.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

:focus {
  outline: 2px solid #ffa1a1;
  border-radius: 2px;
}

/* TimePicker */
.time-picker-container {
  position: relative;
}

.time-picker {
  position: absolute;
  top: 40px;
  left: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 6px;
  display: flex;
  z-index: 9999;
  max-height: 280px;
  overflow: hidden;
}

.hour-picker,
.minute-picker {
  margin: 0px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
}

.hour-picker::-webkit-scrollbar {
  display: none;
}
.minute-picker::-webkit-scrollbar {
  display: none;
}

.hour-picker div,
.minute-picker div {
  cursor: pointer;
  padding: 6px 12px;
}

.hour-picker div:hover,
.minute-picker div:hover {
  background-color: #ffa1a1; /* Highlight on hover */
}
/* TimePicker */

/* Page Size */
.page {
  position: relative;
  width: 21cm;
  min-height: 29.7cm / 2;
  /* max-height: 29.7cm; */
  /* padding: 2cm; */
  padding: 1cm;
  margin: auto;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.page-full {
  min-height: 29.7cm;
}

.page-contact {
  position: fixed;
  bottom: 1.5rem;
  right: 4rem;

  @media print {
    right: 4rem;
  }
}

.page-thankyou {
  position: fixed;
  bottom: 1.5rem;
  width: 80%;

  @media print {
    width: 90%
  }
}

.page-surat-jalan {
  position: fixed;
  bottom: 1.5rem;
  width: 82%;

  @media print {
    width: 90%;
  }
}

.surat-jalan-fill {
  display: inline-block;
    width: 28px;
    border-bottom: 1px solid black;
}

@page {
  margin: 0;
}
@media print {
  .page {
    margin: 0;
    margin-top: -1cm;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}

@media all {
  .page-break {
    display: none;
  }
}

/* @media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
} */

@page {
  .page-break {
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 1cm;
  margin-left: 0;
  margin-right: 0;
}
/* Page Size */

.recharts-cartesian-grid-horizontal line {
  stroke-dasharray: 0;
}
.recharts-cartesian-grid-vertical line {
  stroke-dasharray: 10, 10;
}
