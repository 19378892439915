@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@layer base {
  img {
    @apply inline-block;
  }
  /* :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;
    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;
    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;
    --radius: 0.3rem;
  }

  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;
    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
  } */

  :root {
    --background: 231 100% 100%;
    --foreground: 231 5% 10%;
    --card: 36, 100%, 99%;
    --card-foreground: 231 5% 15%;
    --popover: 231 100% 100%;
    --popover-foreground: 231 100% 10%;
    --primary: 231 82.4% 57.6%;
    --primary-foreground: 0 0% 100%;
    --secondary: 231 30% 90%;
    --secondary-foreground: 0 0% 0%;
    --muted: 193 30% 95%;
    --muted-foreground: 231 5% 40%;
    --accent: 0, 100%, 95%; /* Hover Background */
    --accent-foreground: 231 5% 15%;
    --destructive: 0 100% 50%;
    --destructive-foreground: 231 5% 100%;
    --border: 231 30% 82%;
    --input: 0, 0%, 91%;
    --ring: 0, 100%, 82%;
    --radius: 0.5rem;

    /* Chart */
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }
  .dark {
    --background: 231 50% 10%;
    --foreground: 231 5% 100%;
    --card: 36, 100%, 99%;
    --card-foreground: 231 5% 100%;
    --popover: 231 50% 5%;
    --popover-foreground: 231 5% 100%;
    --primary: 231 82.4% 57.6%;
    --primary-foreground: 0 0% 100%;
    --secondary: 231 30% 20%;
    --secondary-foreground: 0 0% 100%;
    --muted: 193 30% 25%;
    --muted-foreground: 231 5% 65%;
    --accent: 0, 100%, 95%; /* Hover Background */
    --accent-foreground: 231 5% 95%;
    --destructive: 0 100% 50%;
    --destructive-foreground: 231 5% 100%;
    --border: 0, 0%, 91%;
    --input: 231 30% 50%;
    --ring: 0, 100%, 82%;
    --radius: 0.5rem;

    /* Chart */
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}
